var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-relative px-4 pb-4"},[(_vm.$route.params.type)?_c('integration'):_c('section',[_c('h3',{staticClass:"text-left text-secondary font-weight-bolder mr-2 mt-5 mb-4"},[_vm._v(" Adicione outras plataformas ao seu sistema ")]),(_vm.loading)?_c('div',{staticClass:"row"},_vm._l((_vm.integrations),function(integration){return _c('div',{key:integration.key,staticClass:"col-12 col-md-6 col-xl-3"},[_c('b-skeleton-img',{staticClass:"rounded",attrs:{"width":"100%","aspect":"10:11"}})],1)}),0):_c('div',{staticClass:"row"},_vm._l((_vm.integrations),function(integration){return _c('div',{key:integration.key,staticClass:"col-12 col-md-6 col-lg-4 col-xl-3 my-2"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:(integration.disabled ? 'Em breve' : null),expression:"integration.disabled ? 'Em breve' : null",modifiers:{"hover":true,"bottom":true}}],staticClass:"d-flex flex-column justify-content-between p-4 pt-5 h-100 integration-card"},[_c('div',[_c('img',{staticClass:"integration-brand",attrs:{"src":integration.img,"alt":`Integração com a ${integration.name}. Importe clientes e faça envio de mensagens automáticas`}}),_c('p',{staticClass:"my-4 text-center"},[_vm._v(" "+_vm._s(integration.description)+" ")])]),(integration.integrationId && !integration.inactive)?_c('router-link',{attrs:{"to":{
							name: 'settings',
							params: {
								tab: 'integracoes',
								type: integration.key
							}
						},"disabled":integration.loading || integration.disabled},scopedSlots:_vm._u([{key:"default",fn:function({ navigate, href }){return [_c('a',{staticClass:"btn btn-outline-primary",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" Configurar ")])]}}],null,true)}):_c('button',{staticClass:"btn btn-primary position-relative",attrs:{"disabled":integration.loading || integration.disabled},on:{"click":() => _vm.authorize(integration.key, integration.modal ?? null)}},[(integration.loading)?_c('span',{staticClass:"spinner-border spinner-border-sm mr-1",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e(),_vm._v(" "+_vm._s(integration.integrationId && integration.inactive ? "Reativar" : "Integrar")+" ")])],1)])}),0)]),_c('info-tray-modal')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }