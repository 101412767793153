<template>
	<div class="position-relative px-4 pb-4">
		<integration v-if="$route.params.type" />

		<section v-else>
			<h3 class="text-left text-secondary font-weight-bolder mr-2 mt-5 mb-4">
				Adicione outras plataformas ao seu sistema
			</h3>

			<div class="row" v-if="loading">
				<div
					v-for="integration in integrations"
					:key="integration.key"
					class="col-12 col-md-6 col-xl-3"
				>
					<b-skeleton-img width="100%" aspect="10:11" class="rounded"></b-skeleton-img>
				</div>
			</div>

			<div class="row" v-else>
				<div
					class="col-12 col-md-6 col-lg-4 col-xl-3 my-2"
					v-for="integration in integrations"
					:key="integration.key"
				>
					<div
						class="d-flex flex-column justify-content-between p-4 pt-5 h-100 integration-card"
						v-b-tooltip.hover.bottom="integration.disabled ? 'Em breve' : null"
					>
						<div>
							<img
								:src="integration.img"
								:alt="`Integração com a ${integration.name}. Importe clientes e faça envio de mensagens automáticas`"
								class="integration-brand"
							/>
							<p class="my-4 text-center">
								{{ integration.description }}
							</p>
						</div>
						<router-link
							:to="{
								name: 'settings',
								params: {
									tab: 'integracoes',
									type: integration.key
								}
							}"
							v-slot="{ navigate, href }"
							v-if="integration.integrationId && !integration.inactive"
							:disabled="integration.loading || integration.disabled"
						>
							<a class="btn btn-outline-primary" @click="navigate" :href="href">
								Configurar
							</a>
						</router-link>
						<button
							v-else
							class="btn btn-primary position-relative"
							@click="() => authorize(integration.key, integration.modal ?? null)"
							:disabled="integration.loading || integration.disabled"
						>
							<span
								class="spinner-border spinner-border-sm mr-1"
								v-if="integration.loading"
								role="status"
								aria-hidden="true"
							></span>
							{{
								integration.integrationId && integration.inactive
									? "Reativar"
									: "Integrar"
							}}
						</button>
					</div>
				</div>
			</div>
		</section>
		<info-tray-modal />
	</div>
</template>

<script>
import api from "@/api";
import Integration from "./Integration.vue";
import InfoTrayModal from "./components/InfoTrayModal.vue";
import { BSkeletonImg } from "bootstrap-vue";

const disabledIntegrationsRaw = process.env.VUE_APP_DISABLED_INTEGRATIONS;
let disabledIntegrations = [];

if (disabledIntegrationsRaw) disabledIntegrations = disabledIntegrationsRaw.split(",");

export const integrations = [
	{
		key: "nuvemshop",
		modal: null,
		name: "Nuvemshop",
		img: "/assets/brands/nuvemshop.png",
		description:
			"A Nuvemshop é uma plataforma de e-commerce que permite que os lojistas possam lançar, administrar e impulsionar seu negócio digital em qualquer canal de vendas.",
		loading: false,
		integrationId: null
	},
	{
		key: "tray",
		modal: "infoTrayModal",
		name: "Tray",
		img: "/assets/brands/tray.png",
		description:
			"A Tray é uma plataforma de e-commerce completa que oferece ferramentas avançadas para impulsionar suas vendas online. Gerencie estoque, personalize o design, aceite pagamentos e expanda seus negócios com facilidade.",
		loading: false,
		integrationId: null
	}
];

export default {
	components: {
		Integration,
		BSkeletonImg,
		InfoTrayModal
	},
	data() {
		return {
			selectedIntegration: null,
			integrations: integrations.map((e) => ({
				...e,
				disabled: disabledIntegrations.includes(e.key),
				inactive: false
			})),
			loading: true
		};
	},
	mounted() {
		this.load();
	},
	watch: {
		"$route.params": {
			handler() {
				this.load();
			},
			deep: true
		}
	},
	methods: {
		/**
		 * Exibe as mensagens em um toast.
		 *
		 * @param {String} message
		 * @param {'danger'|'success'} type
		 * @returns {void}
		 */
		toast(message, type = "danger") {
			this.$bvToast.toast(message, {
				title: "Integrações",
				autoHideDelay: 2000,
				variant: type
			});
		},
		/**
		 * Realiza o carregamento dos dados de integração.
		 *
		 * @returns {void}
		 */
		load() {
			this.loading = true;
			api.get("integrations")
				.then((res) => {
					const { type, body, message } = res.data;

					if (type === "success") {
						body.forEach((e) => {
							if (e.config != null)
								this.setIntegration(e.type, "integrationId", e._id);

							if (e.status != null)
								this.setIntegration(e.type, "inactive", e.status === "inactive");
						});

						const tray = this.integrations.find((e) => e.key == "tray");

						if (
							tray &&
							!tray.disabled &&
							tray.integrationId == null &&
							this.$route.query.url &&
							this.$route.query.store
						) {
							this.$bvModal.show("infoTrayModal");
						}
					} else {
						this.toast(message);
					}
				})
				.catch((err) => {
					let message = "Não foi possível carregar as integrações";

					if (err.response) message = err.response.data.message;
					this.toast(message);
				})
				.finally(() => (this.loading = false));
		},
		/**
		 * Seta um valor de um determinado serviço no array de integrações.
		 *
		 * @param {String} name Nome do serviço
		 * @param {String} key Chave a ser alterada
		 * @param {any} value Valor a ser inserido
		 * @returns {void}
		 */
		setIntegration(name, key, value) {
			this.integrations = this.integrations.map((e) => {
				if (e.key === name) e[key] = value;
				return e;
			});
		},
		/**
		 * Busca a URL de autorização do serviço.
		 * Comentário: Optei por remover o loading só caso dê erro, assim ele mostra o loading enquanto redireciona.
		 *
		 * @param {String} type Nome do serviço
		 * @param {String} modal Referência do modal
		 * @returns {void}
		 */
		authorize(type, modal = null) {
			if (modal) return this.$bvModal.show(modal);

			this.setIntegration(type, "loading", true);

			api.get(`integrations/${type}/authorize`)
				.then((res) => {
					const { type, body } = res.data;

					if (type === "success") return (window.location.href = body);

					throw res;
				})
				.catch((e) => {
					let message = "Não foi possível estabelecer conexão com a integração";

					if (e.response) message = e.responde.data.message;

					this.toast(message);
					this.setIntegration(type, "loading", false);
				});
		}
	}
};
</script>

<style scoped lang="scss">
.integration-card {
	box-shadow: none !important;
	border: 2px solid #ddd;
	border-radius: 0.5rem !important;

	.integration-brand {
		height: 40px;
		width: auto;
		object-fit: contain;
		object-position: center;
	}
}
</style>
