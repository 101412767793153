<template>
	<section id="dashboard-settings">
		<b-tabs v-model="tab" align="left" nav-class="font-weight-bold">
			<template v-if="pending && user.type === 'owner'">
				<b-tab href="#pagamento-pendente" lazy title="Pagamento pendente">
					<b-card no-body>
						<b-card-body class="text-center position-relative overflow-hidden">
							<pending-plan />
						</b-card-body>
					</b-card>
				</b-tab>
			</template>
			<template v-else-if="!email_verified">
				<b-tab href="aguardando-verificacao-de-email" lazy title="Confirmar email">
					<b-card no-body>
						<b-card-body>
							<verify-email />
						</b-card-body>
					</b-card>
				</b-tab>
			</template>
			<template v-else-if="email_verified && !paid && user.type === 'owner'">
				<b-tab href="#escolher-plano" lazy title="Escolher plano">
					<b-card no-body>
						<b-card-body class="text-center position-relative overflow-hidden">
							<select-plan />
						</b-card-body>
					</b-card>
				</b-tab>

				<b-tab
					v-if="user.plan && !user.plan.is_trial"
					href="#checkout"
					lazy
					title="Checkout"
				>
					<b-card no-body>
						<b-card-body class="position-relative overflow-hidden">
							<checkout />
						</b-card-body>
					</b-card>
				</b-tab>
			</template>

			<template
				v-else-if="
					user.plan &&
					user.type === 'owner' &&
					(plan.used_agents > plan.max_agents || plan.used_phones > plan.max_phones)
				"
			>
				<b-tab lazy title="Remover excessos do plano">
					<b-card no-body>
						<b-card-body class="position-relative overflow-hidden">
							<remove-excess />
						</b-card-body>
					</b-card>
				</b-tab>
			</template>

			<template v-else>
				<b-tab
					v-if="$role(['groups.get', 'groups.update', 'groups.create'])"
					lazy
					title="Permissões"
				>
					<b-card no-body>
						<b-card-body class="text-center position-relative overflow-hidden">
							<permissions />
						</b-card-body>
					</b-card>
				</b-tab>
				<b-tab
					v-if="$role(['groups.get', 'users.get', 'users.create'])"
					:title-link-class="['col-12']"
					lazy
					title="Usuários"
				>
					<users />
				</b-tab>
				<b-tab lazy title="Perfil">
					<b-card no-body>
						<b-card-body class="text-center position-relative overflow-hidden">
							<profile />
						</b-card-body>
					</b-card>
				</b-tab>
				<b-tab v-if="$role(['rules.get', 'rules.create'])" lazy title="Hall">
					<b-card no-body>
						<b-card-body class="text-center position-relative overflow-hidden">
							<hall />
						</b-card-body>
					</b-card>
				</b-tab>
				<b-tab v-if="$role(['phones.get'])" lazy title="Números de Disparo">
					<b-card no-body>
						<b-card-body class="text-center position-relative overflow-hidden">
							<phone-numbers />
						</b-card-body>
					</b-card>
				</b-tab>
				<b-tab lazy title="Planos" v-if="user.type === 'owner'">
					<b-card no-body>
						<b-card-body class="text-center position-relative overflow-hidden">
							<plans />
						</b-card-body>
					</b-card>
				</b-tab>
				<b-tab lazy title="Integrações" v-if="user.type === 'owner'">
					<b-card no-body>
						<b-card-body class="text-center position-relative overflow-hidden">
							<integrations />
						</b-card-body>
					</b-card>
				</b-tab>
			</template>
		</b-tabs>
	</section>
</template>

<script>
import { BCard, BCardBody, BTab, BTabs } from "bootstrap-vue";

import Hall from "@/views/pages/dashboard/settings/Hall.vue";
import Permissions from "@/views/pages/dashboard/settings/Permissions.vue";
import Users from "@/views/pages/dashboard/settings/Users.vue";
import Profile from "@/views/pages/dashboard/settings/Profile.vue";
import PhoneNumbers from "@/views/pages/dashboard/settings/PhoneNumbers.vue";
import Plans from "@/views/pages/dashboard/settings/Plans.vue";
import Integrations from "@/views/pages/dashboard/settings/Integrations.vue";
import SelectPlan from "@/views/pages/dashboard/settings/SelectPlan";
import PendingPlan from "@/views/pages/dashboard/settings/PendingPlan";
import Checkout from "@/views/pages/dashboard/settings/Checkout";
import RemoveExcess from "@/views/pages/dashboard/settings/RemoveExcess";
import VerifyEmail from "@/views/pages/dashboard/settings/VerifyEmail.vue";
import moment from "moment";

export default {
	components: {
		VerifyEmail,
		BCard,
		BCardBody,
		BTab,
		BTabs,
		Hall,
		Permissions,
		Users,
		Profile,
		PhoneNumbers,
		Plans,
		Integrations,
		SelectPlan,
		RemoveExcess,
		Checkout,
		PendingPlan
	},
	computed: {
		pending() {
			const payment = this.user.last_payment ?? null;

			if (!payment) return false;

			return !this.paid && payment.status == "pending";
		},
		paid() {
			const { expires_at } = this.user.type == "owner" ? this.user : this.user.root;
			return expires_at && moment(expires_at).isAfter(moment());
		},
		plan() {
			return this.$store.getters["user/getPlan"];
		},
		email_verified() {
			const { email_verified_at } = this.user;
			return email_verified_at;
		},
		tabs() {
			if (this.paid && this.email_verified && this.email_verified != undefined) {
				return [
					"usuarios",
					"permissoes",
					"perfil",
					"hall",
					"numeros",
					"planos",
					"integracoes"
				];
			}


			if (this.pending) return ["pagamento-pendente"];

			return ["escolher-plano", "checkout", "aguardando-verificacao-de-email"];
		},
		tab: {
			get() {
				const hash = this.$route.hash;
				const paramTab = this.$route.params.tab;

				const index = this.tabs.findIndex((tab) => `#${tab}` === hash || tab === paramTab);
				if ((!this.paid) && index < 0) {
					return this.user.plan && !this.user.plan.is_trial ? 1 : 0;
				}

				return index === -1 ? 0 : index;
			},
			set(val) {
				const tab = this.tabs[val];
				this.$router.push({ name: "settings", params: { tab } });
			}
		}
	}
};
</script>
